.badge {
  position: absolute;
  z-index: var(--z-index-0x);
  top: 9px;
  left: 9px;
  background-color: var(--discount-badge-background-color);
  color: var(--discount-badge-color);
  border-radius: var(--discount-badge-border-radius);
  padding: var(--discount-badge-padding);
  font-weight: var(--font-weight-semibold);
}

.badge.thankYouModalBadge {
  z-index: var(--z-index-3x);
}

.priceDropBadge {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 9px;
  margin-left: 9px;
}
